/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image.page.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@ionic/angular/dist/core.ngfactory";
import * as i3 from "@ionic/angular";
import * as i4 from "./image.page";
import * as i5 from "@angular/platform-browser";
var styles_ImagePage = [i0.styles];
var RenderType_ImagePage = i1.ɵcrt({ encapsulation: 0, styles: styles_ImagePage, data: {} });
export { RenderType_ImagePage as RenderType_ImagePage };
export function View_ImagePage_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "ion-header", [], null, null, null, i2.View_IonHeader_0, i2.RenderType_IonHeader)), i1.ɵdid(1, 49152, null, 0, i3.IonHeader, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 8, "ion-toolbar", [["color", "primary"]], null, null, null, i2.View_IonToolbar_0, i2.RenderType_IonToolbar)), i1.ɵdid(3, 49152, null, 0, i3.IonToolbar, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"] }, null), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "ion-buttons", [["slot", "start"]], null, null, null, i2.View_IonButtons_0, i2.RenderType_IonButtons)), i1.ɵdid(5, 49152, null, 0, i3.IonButtons, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(6, 0, null, 0, 4, "ion-button", [["color", "tertiary"], ["shape", "round"], ["size", "small"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_IonButton_0, i2.RenderType_IonButton)), i1.ɵdid(7, 49152, null, 0, i3.IonButton, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { color: [0, "color"], shape: [1, "shape"], size: [2, "size"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 1, "ion-icon", [["name", "close"], ["slot", "start"]], null, null, null, i2.View_IonIcon_0, i2.RenderType_IonIcon)), i1.ɵdid(9, 49152, null, 0, i3.IonIcon, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], { name: [0, "name"] }, null), (_l()(), i1.ɵted(-1, 0, [" Close "])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "ion-content", [], null, null, null, i2.View_IonContent_0, i2.RenderType_IonContent)), i1.ɵdid(12, 49152, null, 0, i3.IonContent, [i1.ChangeDetectorRef, i1.ElementRef, i1.NgZone], null, null), (_l()(), i1.ɵeld(13, 0, null, 0, 0, "img", [], [[8, "src", 4]], null, null, null, null))], function (_ck, _v) { var currVal_0 = "primary"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "tertiary"; var currVal_2 = "round"; var currVal_3 = "small"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = "close"; _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.value; _ck(_v, 13, 0, currVal_5); }); }
export function View_ImagePage_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image", [], null, null, null, View_ImagePage_0, RenderType_ImagePage)), i1.ɵdid(1, 114688, null, 0, i4.ImagePage, [i3.NavController, i3.ModalController, i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagePageNgFactory = i1.ɵccf("app-image", i4.ImagePage, View_ImagePage_Host_0, { value: "value" }, {}, []);
export { ImagePageNgFactory as ImagePageNgFactory };
